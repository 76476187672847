import React, { Component } from 'react';
import {
    // Row,
    Col,
    Container,
    // Input,
    FormGroup,
    // Label,
    // Form,
    Alert,
    Button,
    Nav, NavItem, NavLink,
    TabContent, TabPane
} from 'reactstrap';
import Select from 'react-select';
import HoleFormItems from './HoleFormItems';
import LineFormItems from './LineFormItems';
import Hole from './Hole';
import Line from './Line';
import ShapeFormItems from './ShapeFormItems';
import Shape from './Shape';
import queryString from 'query-string';
import {getDefaultBoxType, getDefaultBoxSide, getBoxTypes, getDefaultShapeType } from '../../box-helpers';
import { getValidEnclosureTypes } from '../../box-helpers';

class DesignFormSimple extends Component {
    tabs = {
        holeTab: "1",
        lineTab: "2",
        shapeTab: "3"
    }
    diplayClassNames = {
        hideElement: 'd-none',
        showElement: 'd-inline-block'
    }

    validEnclosureTypes = getValidEnclosureTypes(); // ["125B", "1590A", "1590B", "1590BB", "1590BB2", "1590XX", "1590DD"]

    state ={
        boxType: getDefaultBoxType(),
        boxTypes: getBoxTypes(),
        designName: '',
        hasLid: false,
        holeLids: [],
        lineLids: [],
        holeInputs: [],
        lineInputs: [],
        shapeInputs: [],
        public_key: '',
        deletedCount: 0,
        deletedLineCount: 0,
        deletedShapeCount: 0,
        maxLineLimit: 40,
        maxShapeLimit: 40,
        success: '',
        collapse: false,
        collapseText: "Collapse",
        errors: {
            color: '',
            boxType: '',
            holes: {},
            lines: {},
            shapes: {},
            form: ''
        },
        loading: false,
        activeTab: this.tabs.holeTab,
        showShapeButton: this.diplayClassNames.hideElement,
        showLineButton: this.diplayClassNames.hideElement,
        showHoleButton: this.diplayClassNames.showElement,
        validLineEnclosure: true,
        validShapeEnclosure: true
    }

    constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
        // console.log('holes', this.props.holes)

        this.hasLidChange = this.hasLidChange.bind(this);

        const parsed = queryString.parse(window.location.search);
        if (parsed.orderNumber) {
            this.state.orderNumber = parsed.orderNumber
        }
        if (parsed.sku) {
            this.state.sku = parsed.sku
        }
        if (parsed.color) {
            this.state.color = parsed.color
        }
        if (parsed.quantity) {
            this.state.quantity = parsed.quantity
        }
        if (parsed.public_key) {
            this.state.public_key = parsed.public_key
        }
        this.state.boxType = props.boxType;
        // console.log('hi', this.state.holeInputs)
        this.state.holeInputs = Object.keys(this.props.holes).map((key, idx) => {
            return <HoleFormItems key={`hole-items-${key}`} hole={this.props.holes[key]} holeId={`hole${idx+1}`} idx={idx+1} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} hasLidChange={this.hasLidChange} />
        });
        this.state.lineInputs = Object.keys(this.props.lines).map((key, idx) => {
            return <LineFormItems key={`line-items-${key}`} line={this.props.lines[key]} idx={idx+1} addLine={this.props.addLine} resetLines={this.props.resetLines} removeLineFormItem={this.removeLineFormItem}  hasLidChange={this.hasLidChange} />
        });
        this.state.shapeInputs = Object.keys(this.props.shapes).map((key, idx) => {
            return <ShapeFormItems key={`shape-items-${key}`} shape={this.props.shapes[key]} idx={idx+1} addShape={this.props.addShape} resetShapes={this.props.resetShapes} removeShapeFormItem={this.removeShapeFormItem}  hasLidChange={this.hasLidChange} />
        });
    }

    componentDidMount = () => {
        if (this.state.lineInputs.length > 0 && this.state.holeInputs.length === 0) {
            this.setState({
                activeTab: "2"
            });
            this.handleButtonToggle("2")
        } else if(this.state.public_key){
                this.setState({
                    loading: true
                }, () => {
                    this.getBoxDesign_wPublicKey(this.state.public_key)
                })
        } else if (this.state.holeInputs.length === 0) {
            this.addDefaultHoleFormItem()
        }
    }
    getBoxDesign_wPublicKey = async (public_key) => {
        let token = JSON.parse(localStorage.getItem("token"));
        var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_designs/new?public_key=' + public_key, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
        //console.log(result);
        if(result["box_design"]){
            let box_design = result.box_design
            this.setState({
                holeInputs: box_design.box_design_holes.length ? [] : this.state.holeInputs,
                boxType: box_design.enclosure_type,
                loading: false
            }, () => {
                this.generateHoles(box_design)
                this.generateLines(box_design)
                this.generateShapes(box_design)
            });

        }
    }
    generateHoles = async (box_design) => {
        if(box_design.box_design_holes.length > 0){
            await this.props.resetHoles();

            let arr = [];
            box_design.box_design_holes.forEach((a, index) => {
                const holeId = index + 1;
                const hole = <Hole key={`hole${holeId}`} boxType={box_design.enclosure_type} boxSide={a.box_side} holeId={`hole${holeId}`} diameter={a.diameter} positionX={a.position_x} positionY={a.position_y} />
                arr.push(<HoleFormItems key={`hole-items-${holeId}`} hole={ hole } idx={holeId} holeId={`hole${holeId}`} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} hasLidChange={this.hasLidChange} />)

                this.props.addHole(hole);
            })
            this.setState({ holeInputs: arr })
        }
        this.props.handleBoxTypeChangeSelect(box_design.enclosure_type);
    }
    generateLines = async (box_design) => {
        if(box_design.box_design_lines.length > 0){
            await this.props.resetLines();

            let arr = [];
            box_design.box_design_lines.forEach((a, index) => {
                const lineId = index + 1;
                const line = <Line key={`line${lineId}`} boxType={box_design.enclosure_type} boxSide={a.box_side} lineId={`line${lineId}`} positionX1={a.position_x1} positionY1={a.position_y1} positionX2={a.position_x2} positionY2={a.position_y2} />
                arr.push(<LineFormItems key={`line-items-${lineId}`} line={ line } idx={lineId} lineId={`line${lineId}`} addLine={this.props.addLine} resetLines={this.props.resetLines} removeLineFormItem={this.removeLineFormItem} hasLidChange={this.hasLidChange} />)

                this.props.addLine(line);
            })
            this.setState({ lineInputs: arr })
        }
        this.props.handleBoxTypeChangeSelect(box_design.enclosure_type);
    }
    addDefaultHoleFormItem = () => {
        let holeId = 1;
        let arr = []
        const hole = <Hole key={`hole${holeId}`} boxType={getDefaultBoxType()} boxSide={getDefaultBoxSide()} holeId={`hole${holeId}`} diameter="3" positionX="0" positionY="0" />
        arr.push(<HoleFormItems key={`hole-items-${holeId}`} hole={ hole } idx={holeId} holeId={`hole${holeId}`} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} hasLidChange={this.hasLidChange} />)

        this.setState({ holeInputs: arr })
        this.props.addHole(hole);
    }
    removeHoleFormItem = (holeFormKey) => {
        var array = [...this.state.holeInputs];
        //console.log('hfk', holeFormKey);
        let deletedInputs = this.state.deletedCount;
        let newholeInputs = array.filter((a) => a.props.holeId !== holeFormKey)
        this.setState({
            holeInputs: newholeInputs,
            deletedCount: +deletedInputs+1
        })
    }

    addHoleFormItems = () => {
        const holeInputs = {...this.state.holeInputs };
        const boxType = this.state.boxType;
        let deletedCount = this.state.deletedCount;

        const arr = Object.keys(holeInputs).map((key) => {
            if (key) {
                return holeInputs[key];
            }
        });
        const key = Object.keys(holeInputs).length;
        const holeId = (key + 1) + deletedCount;
        const hole = <Hole key={`hole${holeId}`} boxType={boxType} boxSide={getDefaultBoxSide()} holeId={`hole${holeId}`} diameter="3" positionX="0" positionY="0" />
        arr.push(<HoleFormItems key={`hole-items-${holeId}`} hole={ hole } idx={holeId} holeId={`hole${holeId}`} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} hasLidChange={this.hasLidChange} />)

        this.setState({ holeInputs: arr })
        this.props.addHole(hole);
    }

    addDefaultHoleFormItem = () => {
        let holeId = 1;
        let arr = []
        const hole = <Hole key={`hole${holeId}`} boxType={getDefaultBoxType()} boxSide={getDefaultBoxSide()} holeId={`hole${holeId}`} diameter="3" positionX="0" positionY="0" />
        arr.push(<HoleFormItems key={`hole-items-${holeId}`} hole={ hole } idx={holeId} holeId={`hole${holeId}`} addHole={this.props.addHole} resetHoles={this.props.resetHoles} removeHoleFormItem={this.removeHoleFormItem} hasLidChange={this.hasLidChange} />)

        this.setState({ holeInputs: arr })
        this.props.addHole(hole);
    }

    generateLines = async (box_design) => {
        if(box_design.box_design_lines.length > 0){
            await this.props.resetAllLines();

            let arr = [];
            let hasLid = false;
            box_design.box_design_lines.forEach((a, index) => {
                const lineId = index + 1;
                const line = <Line key={`line${lineId}`} boxType={box_design.enclosure_type} boxSide={a.box_side} lineId={`line${lineId}`} positionX1={a.position_x1} positionY1={a.position_y1} positionX2={a.position_x2} positionY2={a.position_y2} />
                arr.push(<LineFormItems key={`line-items-${lineId}`} line={ line } idx={lineId} lineId={`line${lineId}`} addLine={this.props.addLine} resetLines={this.props.resetLines} removeLineFormItem={this.removeLineFormItem}  hasLidChange={this.hasLidChange} />)

                this.props.addLine(line);
                if (a.box_side === "Lid") {
                    hasLid = true;
                }
            })
            this.setState({ lineInputs: arr, hasLid: hasLid })
        }
        this.props.handleBoxTypeChangeSelect(box_design.enclosure_type);
    }

    removeLineFormItem = (lineFormKey) => {
        var array = [...this.state.lineInputs];
        // console.log('lfk', lineFormKey);
        // console.log('arr', array);
        let deletedInputs = this.state.deletedLineCount;
        let newLineInputs = array.filter((a) => a.props.lineId !== lineFormKey)
        this.setState({
            lineInputs: newLineInputs,
            deletedLineCount: +deletedInputs+1
        })
    }

    addLineFormItems = () => {
        const lineInputs = {...this.state.lineInputs };
        const boxType = this.state.boxType;
        let deletedLineCount = this.state.deletedLineCount;
        const arr = Object.keys(lineInputs).map((key) => {
            if (key) {
                return lineInputs[key];
            }
        });
        const key = Object.keys(lineInputs).length;
        const lineId = (key + 1) + deletedLineCount;
        const line = <Line key={`line${lineId}`} boxType={boxType} boxSide={getDefaultBoxSide()} lineId={`line${lineId}`}  positionX1="-10" positionY1="0"  positionX2="10" positionY2="0" />
        arr.push(<LineFormItems key={`line-items-${lineId}`} line={ line } idx={lineId} lineId={`line${lineId}`} addLine={this.props.addLine} resetLines={this.props.resetLines} removeLineFormItem={this.removeLineFormItem} hasLidChange={this.hasLidChange} />)

        this.setState({ lineInputs: arr })
        this.props.addLine(line);
    }

    generateShapes = async (box_design) => {
        if(box_design.box_design_shapes !== undefined && box_design.box_design_shapes.length > 0){
            await this.props.resetAllShapes();

            let arr = [];
            let hasLid = false;
            box_design.box_design_shapes.forEach((a, index) => {
                const shapeId = index + 1;
                // console.log('a', a);
                const shape = <Shape key={`shape${shapeId}`} boxType={box_design.enclosure_type} boxSide={a.box_side} shapeId={`shape${shapeId}`}  shapeType={a.shape_type} positionX={a.position_x} positionY={a.position_y} width={a.width} height={a.height} />
                arr.push(<ShapeFormItems key={`shape-items-${shapeId}`} shape={ shape } idx={shapeId} shapeId={`shape${shapeId}`} addShape={this.props.addShape} resetShapes={this.props.resetShapes} removeShapeFormItem={this.removeShapeFormItem}  hasLidChange={this.hasLidChange} />)

                this.props.addShape(shape);
                if (a.box_side === "Lid") {
                    hasLid = true;
                }
            })
            this.setState({ shapeInputs: arr, hasLid: hasLid })
        }
        this.props.handleBoxTypeChangeSelect(box_design.enclosure_type);
    }
    removeShapeFormItem = (shapeFormKey) => {
        var array = [...this.state.shapeInputs];
        // console.log('lfk', lineFormKey);
        // console.log('arr', array);
        let deletedInputs = this.state.deletedShapeCount;
        let newShapeInputs = array.filter((a) => a.props.shapeId !== shapeFormKey)
        this.setState({
            shapeInputs: newShapeInputs,
            deletedShapeCount: +deletedInputs+1
        })
    }

    addShapeFormItems = () => {
        const shapeInputs = {...this.state.shapeInputs };
        const boxType = this.state.boxType;
        let deletedShapeCount = this.state.deletedShapeCount;
        const arr = Object.keys(shapeInputs).map((key) => {
            if (key) {
                return shapeInputs[key];
            }
        });
        // console.log('arr', shapeInputs)
        const key = Object.keys(shapeInputs).length;
        const shapeId = (key + 1) + deletedShapeCount;
        const shape = <Shape key={`shape${shapeId}`} boxType={boxType} boxSide={getDefaultBoxSide()} shapeType={getDefaultShapeType()} shapeId={`shape${shapeId}`}  positionX="0" positionY="0"  width="10" height="10" />
        arr.push(<ShapeFormItems key={`shape-items-${shapeId}`} shape={ shape } idx={shapeId} shapeId={`shape${shapeId}`} addShape={this.props.addShape} resetShapes={this.props.resetShapes} removeShapeFormItem={this.removeShapeFormItem}  hasLidChange={this.hasLidChange} />)

        this.setState({ shapeInputs: arr })
        this.props.addShape(shape);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let msg = this.validateFormValues(e.target);
        let errors = this.state.errors

        let isValid = this.validateForm(this.state.errors)

        if(isValid && msg === '') {
            errors.form = false
            this.setState({errors})
            // const holeInputs = {...this.state.holeInputs };
            // const lineInputs = {...this.state.lineInputs };
            // const shapeInputs = {...this.state.shapeInputs }

            // const holes = Object.keys(holeInputs).map((key, idx) => {
            //     let keyIdentifier = holeInputs[key].props.idx;
            //     // console.log('holeInputs', keyIdentifier);

            //     let holeSide = 'holeSide'+ keyIdentifier;
            //     let holeDiameter = 'holeDiameter'+ keyIdentifier;
            //     let holePositionX = 'holePositionX'+ keyIdentifier;
            //     let holePositionY = 'holePositionY'+ keyIdentifier;
            //     // Add this to validate no removed input in state
            //     if (e.target[holeSide] && e.target[holeDiameter] && e.target[holePositionX] && e.target[holePositionY]) {
            //         //console.log('key', key);
            //         return {
            //             box_side: e.target[holeSide].value,
            //             diameter: e.target[holeDiameter].value,
            //             positionX: e.target[holePositionX].value,
            //             positionY: e.target[holePositionY].value,
            //         }
            //     }
            // });
            // const lines = Object.keys(lineInputs).map((key, idx) => {
            //     let keyIdentifier = lineInputs[key].props.idx;
            //     // console.log('holeInputs', keyIdentifier);

            //     let lineSide = 'lineSide'+ keyIdentifier;
            //     let linePositionX1 = 'linePositionX1'+ keyIdentifier;
            //     let linePositionY1 = 'linePositionY1'+ keyIdentifier;
            //     let linePositionX2 = 'linePositionX2'+ keyIdentifier;
            //     let linePositionY2 = 'linePositionY2'+ keyIdentifier;
            //     // Add this to validate no removed input in state
            //     if (e.target[lineSide] && e.target[linePositionX1] && e.target[linePositionY1] && e.target[linePositionX2] && e.target[linePositionY2]) {
            //         //console.log('key', key);
            //         return {
            //             box_side: e.target[lineSide].value,
            //             positionX1: e.target[linePositionX1].value,
            //             positionY1: e.target[linePositionY1].value,
            //             positionX2: e.target[linePositionX2].value,
            //             positionY2: e.target[linePositionY2].value,
            //         }
            //     }
            // });
            // console.log("holes", holes)
            // send email
            // const requestOptions = {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({
            //         order_number: e.target.orderNumber.value.trim(),
            //         name: e.target.designName.value.trim(),
            //         sku: e.target.sku.value,
            //         enclosure_type: e.target.boxType.value,
            //         enclosure_sku: e.target.enclosureSku.value,
            //         color: e.target.color.value,
            //         quantity: e.target.quantity.value,
            //         holes: holes
            //     })
            // };

            // // fetch('http://www.taydaopc.com/api/v3/boxes', requestOptions)
            // fetch(getApiUrl(), requestOptions)
            //     .then(this.handleErrors)
            //     .then(data => {
            //         if (this.state.errors.form !== "" && !data.message.includes('Success')) {
            //             errors.form = data.message;
            //             this.setState({  errors })
            //         } else {
            //             window.scrollTo(0, 0)
            //             this.setState({ success: data.message })
            //         }
            //     }).catch(function(error) {
            //         console.log(error);
            //     });
        }
    }

    handleErrors = (response) => {
        let errors = this.state.errors
        if (!response.ok) {
            errors.form = "ERROR"
            this.setState({errors})
            // throw Error(response.statusText);
        }
        return response.json();
    }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    validateFormValues = (formObj) => {
        let msg = '';

        let diameterInput = 1;
        for (let i = 0; i < formObj.length; i++) {
            if (formObj.elements[i].getAttribute("name") === "holeDiameter"+diameterInput) {
                // console.log("fo" + diameterInput, formObj.elements[i].getAttribute("name"))
                diameterInput += 1;
                if (!formObj.elements[i].value || formObj.elements[i].value < 3) {
                    msg = "Minimum hole diameter is 3mm. Make sure the hole diameter inputs have a value."
                }
            }

        // console.log('HINPUTS', this.state.holeInputs);
        }
        let errors = this.state.errors
        errors.form = msg
        this.setState({errors})

        return msg;
    }

    validateFormFields = (name, value) => {
        let errors = this.state.errors;
        switch (name) {
            // case 'orderNumber':
            //     errors.orderNumber =
            //     value.length < 8
            //         ? 'Order Number must be at least 8 characters long!'
            //         : '';
            //     break;
            // case 'color':
            //     errors.color = '';
            //     break;
            // case 'sku':
            //     errors.sku =
            //     value.length === 0
            //         ? 'Drill Product required'
            //         : '';
            //     break;
            // case 'enclosureSku':
            //     errors.enclosureSku =
            //     value.length === 0
            //     ? 'Enclosure Product required'
            //     : '';
            //     break;
            // case 'quantity':
            //     errors.quantity = '';
            //     break;

            default:
                break;
        }
        // if (name != 'color') {
        //     errors.color = '';
        // }
        return errors;
    }

    handleChange = (e) => {
        if (e) {
            const { name, value } = e.target;
            let errors =   this.validateFormFields(name, value);
            this.setState({errors, [name]: value})
        }
    }


    handleSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;

            this.setState({[name]: value, [name+"label"]: label})
        }
    }

    handleTypeChange = (e) => {
        if (e) {
            // console.log(e);
            const {name, value, label} = e;

            this.setState({boxType: value , [name+"label"]: label});
            this.props.handleBoxTypeChangeSelect(value);
        }
    }
    hasLidChange = (cut_id, box_side, cut_type) => {
        let hasLid = false;
        let holeLids = this.state.holeLids;
        let lineLids = this.state.lineLids;
        if (cut_type === "hole") {
            holeLids = holeLids.filter((e) => e !== cut_id);
        } else if (cut_type === "line") {
            lineLids = lineLids.filter((e) => e !== cut_id);
        }
        if (holeLids.length > 0 || lineLids.length > 0) {
            hasLid = true;
        } else {
            if (box_side === "Lid") {
                if (cut_type === "hole") {
                    holeLids.push(cut_id);
                }
                if (cut_type === "line") {
                    lineLids.push(cut_id);
                }
                hasLid = true;
            }
        }
        this.setState({
            hasLid: hasLid,
            holeLids: holeLids
        })
    }
    checkLineValid = (val) => {
        return this.validEnclosureTypes.indexOf(val) >= 0;
    }
    invalidLineSubmit = (val) => {
        let isValid = this.checkLineValid(val);
        return isValid !== true && (this.state.activeTab === "2" || this.state.lineInputs.length > 0)
    }
    lineEnclosureStr = () => {
        return this.validEnclosureTypes.join(" and ");
    }
    handleButtonToggle = (tab) => {
        let showHoleButton = tab === this.tabs.holeTab ? this.diplayClassNames.showElement : this.diplayClassNames.hideElement;
        let showLineButton = tab === this.tabs.lineTab ? this.diplayClassNames.showElement : this.diplayClassNames.hideElement;
        let showShapeButton = tab === this.tabs.shapeTab ? this.diplayClassNames.showElement : this.diplayClassNames.hideElement;
        this.setState({
            showHoleButton: showHoleButton,
            showLineButton: showLineButton,
            showShapeButton: showShapeButton
        });
    }
    toggle = (e) => {
        if (this.state.collapse === false) {
            this.setState({ collapse: true, collapseText: "Expand"})
        } else {
            this.setState({ collapse: false, collapseText: "Collapse"})
        }
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            this.handleButtonToggle(tab);
        }
    }

    render() {
        return (
            <Container className="m-4">
                {!this.state.success && (
                    <form action="" className="add-design" onSubmit={this.handleSubmit}>
                        <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Enclosure Type</label>
                                    <Select
                                        key="boxType"
                                        name="boxType"
                                        isClearable={true}
                                        value={{ value: this.state.boxType, label: this.state.boxType}}
                                        onChange={this.handleTypeChange}
                                        options={this.state.boxTypes.map((t) => ({ value: t.key, label: t.key }))}
                                    />
                                </Col>
                            </FormGroup>
                        <FormGroup row>
                            <Col className="col" md={12}>

                                <label className="col-form-label">Holes</label>
                                <Alert color="warning" className="xs-box">
                                    <small className="text-xs" style={{ fontWeight:500}}>
                                        MAKE SURE TO TAKE COORDINATES FROM CENTER OF SIDES IN MILLIMETERS.<br />
                                        PLEASE ADD 0.2MM TO DIAMETER HOLES FOR POWDER COATING THICKNESS.<br />
                                        FOR EXAMPLE IF YOU NEED 8MM DIA HOLE, INPUT 8.20. PLEASE USE "." POINT FOR DECIMALS, PLEASE DON'T USE COMMA ","  , FOR EXAMPLE 5.36 , NOT 5,36
                                    </small>
                                </Alert>
                                <Alert color="info" className="xs-box">
                                    <small className="text-xs" style={{ fontWeight:500}}>
                                        WITH {this.lineEnclosureStr()} ENCLOSURES, YOU CAN CREATE RECTANGLES, TRIANGLES OR EVEN STAR LIKE SHAPES.
                                        UNDER LINES TAB, CLICK '+ Add Line' BUTTON TO CREATE LINES AND PUT THEM TOGETHER TO MAKE ANY SHAPE YOU WANT. TRY IT, WE HOPE YOU WILL LOVE IT!
                                    </small>
                                </Alert>
                                <Nav tabs className="design-tabs">
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === '1' ? "active" : ""} onClick={() => { this.toggleTab('1'); }} >
                                            <label className="col-form-label py-0">Holes</label>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={this.state.validLineEnclosure === true ? "" : this.diplayClassNames.hideElement}>
                                        <NavLink className={this.state.activeTab === '2' ? "active" : ""} onClick={() => { this.toggleTab('2'); }} >
                                            <label className="col-form-label py-0">Lines</label>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={this.state.validShapeEnclosure === true ? "" : this.diplayClassNames.hideElement}>
                                        <NavLink className={this.state.activeTab === '3' ? "active" : ""} onClick={() => { this.toggleTab('3'); }} >
                                            <label className="col-form-label py-0">Shapes</label>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <div className="hole-form-items">
                                            {this.state.holeInputs}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="line-form-items">
                                            {this.state.lineInputs}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <div className="shape-form-items">
                                            {this.state.shapeInputs}
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="my-4">
                            <Col className="col text-right" md={8}>
                                <div className={this.state.showShapeButton}>
                                    <Button color="info" className="btn btn-sm mx-3" id="add-shape-btn" key="add-shape-btn" onClick={this.addShapeFormItems}>+ Add Shape</Button>
                                </div>
                                <div className={this.state.showLineButton}>
                                    <Button color="info" className="btn btn-sm mx-3" id="add-line-btn" key="add-line-btn" onClick={this.addLineFormItems}>+ Add Line</Button>
                                </div>
                                <div className={this.state.showHoleButton}>
                                    <Button color="primary" className="btn btn-sm mx-3" id="add-hole-btn" key="add-hole-btn" onClick={this.addHoleFormItems}>+ Add Hole</Button>
                                </div>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col className="col text-right" md={12}>
                                <Alert className="text-left" color="danger" isOpen={this.state.errors.form ? true : false}>{this.state.errors.form}</Alert>
                                {/* <Button color="success" type="submit">Save Design</Button> */}
                            </Col>
                        </FormGroup>
                    </form>
                )}
            </Container>
        );
    }
}

export default DesignFormSimple;