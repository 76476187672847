import React, { Component } from 'react';
import { humanize } from '../../box-helpers';

import {
    Container,
    FormGroup,
    Input,
    Col,
    Alert,
    Button,
    Collapse,
    Row,
    Form,
    Label
} from 'reactstrap';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';

import {getValidEnclosureTypes} from '../../box-helpers';

class CustomerBoxConfigurationForm extends Component {
  state = {
    configurationName: '',
    boxType: '',
    powderColor: '',
    productId: '',
    enclosureProducts: [],
    selectedEnclosureProduct: '',
    drillProducts: [],
    selectedDrillProducts: [],
    uvProducts: [],
    selectedUvProducts: [],
    serviceProducts: [],
    selectedServiceProducts: [],
    selectedProducts: [],
    boxTypes: getValidEnclosureTypes(),
    colors: [],
    loading: false,
    collapse: true,
    showAlert: false,
    alertMsg: '',
    errors: {
       form: ''
    },
  }

  constructor(props) {
    super(props);

    this.handleBoxTypeChangeSelect = this.handleBoxTypeChangeSelect.bind(this)
    this.handlePowderColorChangeSelect = this.handlePowderColorChangeSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getPowderColors = this.getPowderColors.bind(this)
    this.fetchAvailableProducts = this.fetchAvailableProducts.bind(this)

  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log('submitting form');
    let token = JSON.parse(localStorage.getItem("token"));
    fetch(process.env.REACT_APP_API_URL + '/api/v4/products/enclosure_products', {
        method: 'POST',
        headers: {
          'Authorization': "Bearer " + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ignore_flags:  true })
      })
      .then(response => response.json())
      .then(data => {
        console.log('data', data);
        return data;
      })
      .catch((error) => {
        this.setState({ showAlert: true })
        return error.message;
      });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  clearProductValues = () => {
    this.setState({
      collapse: true,
      powderColor: '',
      productId: '',
      enclosureProducts: [],
      selectedEnclosureProduct: '',
      selectedProducts: [],
      drillProducts: [],
      selectedDrillProducts: [],
      uvProducts: [],
      selectedUvProducts: [],
      serviceProducts: [],
      selectedServiceProducts: []
    });
  }

  handleEnclosureChange = (val) => {
    this.clearProductValues();

    this.setState({
      selectedEnclosureProduct: val
    })
    this.fetchProduct(val);
  }

  handleBoxTypeChangeSelect = (val) => {
    if(val == null){
      this.clearProductValues();
      this.setState({
        boxType: "",
        colors: [],
      })
    }else{
      this.clearProductValues();
      if (val.value) {
        this.setState({
          boxType: val.value,
        })
        this.getPowderColors(val.value);
      }
    }
  }

  handlePowderColorChangeSelect = (val) => {
    console.log(val);
    if(val == null){
      this.clearProductValues();
      this.setState({
        powderColor: "",
      })
      return 
    }else{
      let value = val.value
      if (value) {
        this.clearProductValues();
        this.setState({
          powderColor: value,
        })
        this.fetchAvailableProducts(this.state.boxType, value);
      }
    }
  }

  handleCheckboxChange = (product_id, product_type) => {
    this.setState((prevState) => {
      const selectedCBProducts = prevState[product_type] || [];
      if (selectedCBProducts.includes(product_id)) {

        return {
          [product_type]: selectedCBProducts.filter(id => id !== product_id),
          selectedProducts: prevState.selectedProducts.filter(p => p.id !== product_id)
        };
      } else {
        console.log('adding product_id', product_id);
        this.fetchProduct(product_id);
        return {
          [product_type]: [...selectedCBProducts, product_id],
        };
      }

    });
  };
  getPowderColors = (box_type) => {
    let colors = [];

    if (box_type) {
      this.setState({
        showAlert: true
      }, async () => {
        console.log('fetching colors for box_type', box_type);
        var result =  await this.submitGetRequest(process.env.REACT_APP_API_URL + '/api/v4/products/powder_colors?enclosure_type=' + box_type);

        if(result["errors"]){
          console.log('result error: ',result["errors"]);
          let errors = "";
          if (typeof result["errors"] === 'string') {
              errors = result["errors"];
          } else {
              errors = Object.keys(result["errors"]).map(key => {
                  let human_key = humanize(key);
                  return  human_key + " " + result["errors"][key];
              })
          }
          this.setState({ alertMsg: errors, showAlert: true})
        }else{
            colors = result;
            this.setState({ colors: colors, loading: false });
            // console.log(this.state.box_job);

        }
      })
    }
  }

  fetchProduct = async (product_id) => {
    console.log('fetching product for product_id', product_id);
    if (product_id) {
      const result = await this.submitGetRequest(`${process.env.REACT_APP_API_URL}/api/v4/products/${product_id}`);
      if (result.errors) {
        console.log('result error: ', result.errors);
        let errors = "";
        if (typeof result.errors === 'string') {
          errors = result.errors;
        } else {
          errors = Object.keys(result.errors).map(key => {
            let human_key = humanize(key);
            return human_key + " " + result.errors[key];
          }).join(", ");
        }
        this.setState({ alertMsg: errors, showAlert: true, loading: false });
      } else {
        console.log('result', result);
        this.setState((prevState) => ({
          selectedProducts: [...prevState.selectedProducts, result],
          loading: false
        }));
      }
    }
  }

  fetchAvailableProducts = async (box_type, powder_color) => {
    let products = [];
    if (box_type && powder_color) {
      this.setState({ loading: true });

      try {
        console.log('fetching products for box_type', box_type, 'powder_color', powder_color);
        const result = await this.submitGetRequest(`${process.env.REACT_APP_API_URL}/api/v4/products?enclosure_type=${box_type}&powder_color=${powder_color}`);

        if (result.errors) {
          console.log('result error: ', result.errors);
          let errors = "";
          if (typeof result.errors === 'string') {
            errors = result.errors;
          } else {
            errors = Object.keys(result.errors).map(key => {
              let human_key = humanize(key);
              return human_key + " " + result.errors[key];
            }).join(", ");
          }
          this.setState({ alertMsg: errors, showAlert: true, loading: false });
        } else {
          products = result;

          let enclosureProducts = products.filter(p => p.enclosure_type === box_type && p.powder_color && p.powder_color !== '');
          let drillProducts = products.filter(p => p.is_drill_product === true && !p.is_predrill);
          let uvProducts = products.filter(p => p.is_uv_product === true);
          let serviceProducts = products.filter(p => p.is_service_product === true);
          console.log('drillProducts', drillProducts);
          console.log('uvProducts', uvProducts);
          console.log('serviceProducts', serviceProducts);
          console.log('enclosureProducts', enclosureProducts);
          this.setState({ enclosureProducts, drillProducts, uvProducts, serviceProducts, loading: false, collapse: false });
        }
      } catch (error) {
        console.log('error', error);
        this.setState({ showAlert: true, alertMsg: error.message });
      }
    }

  }

  submitGetRequest = async (url) => {
    let token = JSON.parse(localStorage.getItem("token"));
    var result =  await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': "Bearer " + token,
          'Content-Type': 'application/json'
        },
    })
    .then(response => response.json())
    .then(data => {
      console.log('data', data);
      return data;
    })
    .catch((error) => {
      console.log('error', error);
      this.setState({ showAlert: true, alertMsg: error.message });
      return error.message;
    });
    return result;
  }

  render() {
      return (
        <Container className="m-4">
          <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
          />
          { this.state.loading && (
              <div className="loading style-2">
                  <div className="loading-wheel"></div>
                  <h3 className="loading-message">THIS MIGHT TAKE A FEW MINUTES. PLEASE WAIT...</h3>
              </div>
          )}

          {!this.state.success && (
              <form action="" className="edit-configuration" onSubmit={this.handleSubmit}>
                <Row>
                  <Col className="col" md={6}>
                    <h5 className="row bg-secondary p-2 text-white">Choose You Enclosure Attributes</h5>
                    <FormGroup row>
                        <Col className="col" md={12}>
                            <label className="col-form-label">Box Configuration Name</label>
                            <Input
                                key="configurationName"
                                id="configurationName"
                                name="configurationName"
                                placeholder="Box Configuration Name"
                                onChange={this.handleChange}
                                value={this.state.configurationName} />
                        </Col>
                    </FormGroup>

                        <FormGroup row>
                            <Col className="col" md={12}>
                                <label className="col-form-label">Enclosure Type</label>
                                <Select
                                    key="boxType"
                                    name="boxType"
                                    isClearable={true}
                                    value={{ value: this.state.boxType, label: this.state.boxType}}
                                    onChange={this.handleBoxTypeChangeSelect}
                                    options={this.state.boxTypes.map((t) => ({ value: t, label: t, name: "boxType" }))}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col" md={12}>
                                <label className="col-form-label">Color</label>
                                <Select
                                    key="powderColor"
                                    name="powderColor"
                                    isClearable={true}
                                    value={{ value: this.state.powderColor, label: this.state.powderColor}}
                                    onChange={this.handlePowderColorChangeSelect}
                                    options={this.state.colors.map((t) => ({ value: t, label: t, name: "powderColor" }))}
                                />
                                <Input type="hidden" name="productId" key="productId" value={this.state.productId}  />
                            </Col>
                        </FormGroup>
                    <Collapse isOpen={this.state.collapse === false}>
                      <FormGroup row>
                          <Col className="col" md={12}>
                              <label className="col-form-label row bg-secondary text-white p-2 my-2">Enclosure Products</label>
                              {this.state.enclosureProducts.map((product) => (
                                <FormGroup check key={"enclosure-products-" + product.id}>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="enclosureProducts"
                                      value={product.id }
                                      checked={this.state.selectedEnclosureProduct === product.id}
                                      onChange={() => this.handleEnclosureChange(product.id)}
                                    />
                                    {product.sku + " " + product.name}
                                  </Label>
                                </FormGroup>
                              ))}
                          </Col>
                      </FormGroup>
                      <FormGroup row>
                          <Col className="col" md={12}>
                            <label className="col-form-label row bg-secondary text-white p-2 my-2">Drill Products</label>
                              {this.state.drillProducts.map((product) => (
                                <FormGroup check key={"drill-products-" + product.id}>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      name="drillProducts"
                                      value={product.id }
                                      checked={this.state.selectedDrillProducts.includes(product.id)}
                                      onChange={() => this.handleCheckboxChange(product.id, "selectedDrillProducts")}
                                    />
                                    {product.sku + " " + product.name}
                                  </Label>
                                </FormGroup>
                              ))}
                          </Col>
                      </FormGroup>
                      <FormGroup row>
                          <Col className="col" md={12}>
                            <label className="col-form-label row bg-secondary text-white p-2 my-2">UV Products</label>
                              {this.state.uvProducts.map((product) => (
                                <FormGroup check key={"uv-products-" + product.id}>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      name="uvProducts"
                                      value={product.id}
                                      checked={this.state.selectedUvProducts.includes(product.id)}
                                      onChange={() => this.handleCheckboxChange(product.id, "selectedUvProducts")}
                                    />
                                    {product.sku + " " + product.name}
                                  </Label>
                                </FormGroup>
                              ))}
                          </Col>
                      </FormGroup>
                    </Collapse>
                  </Col>
                  <Col className="col" md={6}>
                    <div className='ml-2'>
                    <h5 className="row bg-secondary p-2 text-white">Selected Products</h5>
                    { this.state.selectedProducts.length > 0 && (
                      <FormGroup row>
                        <Col className="col" md={12}>
                          <Container>
                            {this.state.selectedProducts.map((product, index) => (
                              product && (
                                <div key={"product-" + product.id} >
                                  <Row className="justify-content-center align-items-center">
                                    <Col>
                                      <img src={process.env.REACT_APP_API_URL + product.product_photo_url} alt={product.name} className="mx-1" style={{maxWidth:'100px'}}/>
                                    </Col>
                                    <Col>
                                      {product.sku}
                                    </Col>
                                    <Col>
                                      {product.name}
                                    </Col>
                                  </Row>
                                  {(product.tier_price != null || product.tier_price != "") &&
                                  <Row>
                                    <Col className="text-right">
                                      <h7>{product.tier_price}</h7>
                                    </Col>
                                  </Row>
                                  }
                                  {this.state.selectedProducts.length-1 != index &&
                                    <hr />
                                  }
                                </div>
                              )
                            ))}
                          </Container>
                        </Col>
                      </FormGroup>
                    )}
                    <FormGroup row>
                      <Col className="col text-right" md="12">
                        <Alert className="text-left" color="danger" isOpen={this.state.errors.form ? true : false}>{this.state.errors.form}</Alert>
                        <Button color="success" type="submit">Save UV Design</Button>
                      </Col>
                    </FormGroup>
                    </div>
                  </Col>
                </Row>
              </form>
          )}
      </Container>
    )
  }
}

export default CustomerBoxConfigurationForm;