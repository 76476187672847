import React from 'react';
import CustomerBoxConfigurationForm from './CustomerBoxConfigurationForm';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

import {
    Row,
    Col,
    Container,
} from 'reactstrap';
// import {getDefaultBoxType } from '../../box-helpers';


class BoxConfigurationWrapper extends React.Component {

    // state = {
    //     boxType: '',
    //     powderColor: '',
    // }


    // constructor(props) {
    //     super(props);

    //     // this.handleBoxTypeChangeSelect = this.handleBoxTypeChangeSelect.bind(this)
    //     // this.handlePowderColorChangeSelect = this.handlePowderColorChangeSelect.bind(this)
    //     // const parsed = queryString.parse(window.location.search);

    // }

    // componentDidMount = () => {
    //     const parsed = queryString.parse(window.location.search);
    //     this.setState({
    //         boxType: parsed.boxType
    //     })

    //     this.handleBoxTypeChangeSelect();

    // }



    render() {

        return (
            <div>
                <Header />
                <Container fluid>
                    <Row id="box-wrapper">
                        <Col className="col pl-0" lg={12} md={12} sm={12} xs={12}>
                            <div id="form-wrapper">
                                <CustomerBoxConfigurationForm  />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }

}

export default BoxConfigurationWrapper;