import React, { Component } from "react";
import ReactPaginate from 'react-paginate';

import {
    Form, FormGroup, Label,
    Input, Row, Col
} from 'reactstrap';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BoxJob from '../BoxCustomer/BoxJob';
import OAuthService from '../Auth/OAuthService';


class BoxJobPagination extends Component {
    state = {
        box_jobs: [],
        page_count: 0,
        page_size: 20,
        page: 0,
        tooltipOpen: false,
        popoverOpen: false,
        setTooltipOpen: false
    }

    constructor(props){
        super(props);


        this.PAGE_SIZE_KEY = this.props.page_size_key;
        let page_size = localStorage.getItem(this.PAGE_SIZE_KEY) || 20;
        this.state.page_size = page_size;
        this.os = this.props.os || new OAuthService();
        this.getList(1);
    }

    boxJobRows = () => {
        let boxJobRows = this.state.box_jobs.map( key => {
            return <BoxJob key={key.id} box_job={key}></BoxJob>
        })
        return boxJobRows;
    }

    getList = async (new_page) => {
        let token = JSON.parse(localStorage.getItem("token"));
        this.os.getBoxJobs(token, new_page, this.state.page_size).then((result) => {
            // console.log("box_jobs", result);
            this.setState({
                box_jobs: result["box_jobs"],
                page_count: result["meta"]['page_count'],
                page: new_page - 1
            })
        }).catch((error) => {
            console.log('error', error);
            this.setState({
                box_jobs: [],
            })
        })

    }

    handlePageClick = (data) => {
        //console.log(data);
        this.getList(data.selected + 1);
    }

    handleChange = (e) => {
        localStorage.setItem(this.PAGE_SIZE_KEY, e.target.value);
        this.setState({
            page_size: e.target.value
        }, () => {
            this.getList(1);
        })
    }

    getIndex = (index) => {
        if(this.state.page == 0){
            return (index + 1);
        }else{
            return (this.state.page*this.state.page_size) + (index + 1);
        }
    }

    toggleTooltip = () => {
        this.setState({tooltipOpen: !this.state.tooltipOpen})
        return this.state.toggleTooltip
    }

    togglePopover = () => {
        this.setState({popoverOpen: !this.state.popoverOpen})
        return this.state.togglePopover
    }


    render() {
        return (
            <div>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />
                {this.state.box_jobs.length > 0 &&
                    <div>
                        <div>
                            <hr style={{border: '2px solid rgba(0,0,0,.1)'}}/>
                            <h4>Box Jobs</h4>
                            <Row className="my-2">
                                <Col className="col" md={1}>JobID</Col>
                                <Col className="col" md={2}>Order #</Col>
                                <Col className="col" md={1}>Qty</Col>
                                <Col className="col" md={4}>Enclosure</Col>
                                <Col className="col" md={3}>Status</Col>
                                <Col className="col text-right" md={1}>Created</Col>
                            </Row>
                            {this.boxJobRows()}
                        </div>
                        <div className="d-flex justify-content-between">
                            { this.state.page_count > 1 && (
                                <div>
                                    <div style={{padding: '4px 0px'}}>
                                        <Form>
                                            <FormGroup className="form-inline form-short">
                                                <Label className="mr-1">Shows: </Label>
                                                <Input type="select" name="select" id="exampleSelect" onChange={this.handleChange} value={this.state.page_size}>
                                                    <option>10</option>
                                                    <option>20</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                </Input>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                    <ReactPaginate
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.page_count}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        )
    }

}
export default BoxJobPagination;