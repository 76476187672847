const apiUrl =  'https://www.taydaopc.com/api/v3/box_designs' // 'http://localhost:3002/api/v3/box_designs'  // 'https://dev.taydaopc.com/api/v3/boxes' //
const defaultBoxType = "125B";
const defaultBoxSide = "A";
const defaultShapeType = "Rectangle";
const defaultDrillHoleQuantity = 40;
const validEnclosureTypes = ["125B", "1590A", "1590B", "1590BB", "1590BB2", "1590XX", "1590DD"]
const boxes = {
    // 1590A
    // 1590B
    // 125B
    // 1590BB
    // 1590XX
    // 1590DD
    // 122mm x 66mm x 39.5mm
    // 121.5 X 66.5 X 35
    // mm to pixel variable 3.436
    '125B': {
        x: {
            A: "132.3",
            B: "132.3",
            C: "0",
            D: "132.3",
            E: "383.67",
            Lid: "523.67"
        },
        y: {
            A: "132.3",
            B: "0",
            C: "132.3",
            D: "591.57",
            E: "132.3",
            Lid: "132.3"
        },
        width: {
            A: "251.37",
            B: "251.37",
            C: "132.3",
            D: "251.37",
            E: "132.3",
            Lid: "251.37"
        },
        height: {
            A: "459.27",
            B: "132.3",
            C: "459.27",
            D: "132.3",
            E: "459.27",
            Lid: "459.27"
        }
    },
    //Dimensions: 92mm x 38mm x 30mm
    // 91.5 X 38.5 X 25.5
    '1590A': {
        x: {
            A: "96.39",
            B: "96.39",
            C: "0",
            D: "96.39",
            E: "241.92",
            Lid: "346.01"
        },
        y: {
            A: "96.39",
            B: "0",
            C: "96.39",
            D: "442.26",
            E: "96.39",
            Lid: "96.39"
        },
        width: {
            A: "145.53",
            B: "145.53",
            C: "96.39",
            D: "145.53",
            E: "96.39",
            Lid: "145.53"
        },
        height: {
            A: "345.87",
            B: "96.39",
            C: "345.87",
            D: "96.39",
            E: "345.87",
            Lid: "345.87"
        }
    },
    //Dimensions: 112 X 60 X 31mm
    // 110 X 58 X 26.5
    '1590B': {
        x: {
            A: "100.17",
            B: "100.17",
            C: "0",
            D: "100.17",
            E: "319.41",
            Lid: "427.34"
        },
        y: {
            A: "100.17",
            B: "0",
            C: "100.17",
            D: "515.97",
            E: "100.17",
            Lid: "100.17"
        },
        width: {
            A: "219.24",
            B: "219.24",
            C: "100.17",
            D: "219.24",
            E: "100.17",
            Lid: "219.24"
        },
        height: {
            A: "415.8",
            B: "100.17",
            C: "415.8",
            D: "100.17",
            E: "415.8",
            Lid: "415.8"
        }
    },
    // Dimensions: 120mm x 94mm x 33mm
    // 117.5 X 92 X 30
    '1590BB': {
        x: {
            A: "113.4",
            B: "113.4",
            C: "0",
            D: "113.4",
            E: "461.16",
            Lid: "582.26"
        },
        y: {
            A: "113.4",
            B: "0",
            C: "113.4",
            D: "557.55",
            E: "113.4",
            Lid: "113.4"
        },
        width: {
            A: "347.76",
            B: "347.76",
            C: "113.4",
            D: "347.76",
            E: "113.4",
            Lid: "347.76"
        },
        height: {
            A: "444.15",
            B: "113.4",
            C: "444.15",
            D: "113.4",
            E: "444.15",
            Lid: "444.15"
        }
    },
    // Dimensions: 120mm x 94mm x 36.5mm
    // 36.5 130.57 - 125.414 = 5.156   38 -130.57-113.4 =17.17
    '1590BB2': {
        x: {
            A: "125.414",
            B: "125.414",
            C: "0",
            D: "125.414",
            E: "473.174",
            Lid: "606.288"
        },
        y: {
            A: "125.414",
            B: "0",
            C: "125.414",
            D: "569.564",
            E: "125.414",
            Lid: "125.414"
        },
        width: {
            A: "347.76",
            B: "347.76",
            C: "125.414",
            D: "347.76",
            E: "125.414",
            Lid: "347.76"
        },
        height: {
            A: "444.15",
            B: "125.414",
            C: "444.15",
            D: "125.414",
            E: "444.15",
            Lid: "444.15"
        }
    },
    // Dimensions: 117mm x 185mm x 33mm | *3.78
    // A - 117 x 185 | B - 117 x 33 | C - 33 x 185mm | D - 117 x 33 | E - 33 x 185
    // x 33 + 117 | y 33 + 185
    '1590DD': {
        x: {
            A: "113.4",
            B: "113.4",
            C: "0",
            D: "113.4",
            E: "555.66",
            Lid: "675.66"
        },
        y: {
            A: "113.4",
            B: "0",
            C: "113.4",
            D: "812.7",
            E: "113.4",
            Lid: "113.4"
        },
        width: {
            A: "442.26",
            B: "442.26",
            C: "113.4",
            D: "442.26",
            E: "113.4",
            Lid: "442.26"
        },
        height: {
            A: "699.3",
            B: "113.4",
            C: "699.3",
            D: "113.4",
            E: "699.3",
            Lid: "699.3"
        }
    },
    // Dimensions: 145 X 121 X 39.5mm
    // 141 X 117 X 35
    '1590XX': {
        x: {
            A: "132.3",
            B: "132.3",
            C: "0",
            D: "132.3",
            E: "574.56",
            Lid: "712.56",
        },
        y: {
            A: "132.3",
            B: "0",
            C: "132.3",
            D: "665.28",
            E: "132.3",
            Lid: "132.3"
        },
        width: {
            A: "442.26",
            B: "442.26",
            C: "132.3",
            D: "442.26",
            E: "132.3",
            Lid: "442.26"
        },
        height: {
            A: "532.98",
            B: "132.3",
            C: "532.98",
            D: "132.3",
            E: "532.98",
            Lid: "532.98"
        }
    }
}
const boxLabels = {
    A: "Side A / Face",
    B: "Side B / Top",
    C: "Side C / Left",
    D: "Side D / Bottom",
    E: "Side E / Right",
    Lid: "Enclosure Lid",
}

export function getPositionX(boxType, boxSide) {
    let value = boxes[boxType]['x'][boxSide];

    return value;
}

export function getPositionXCenter(boxType, boxSide) {
    let value = getPositionX(boxType, boxSide);
    let width = getWidth(boxType, boxSide);

    return +value + (+width/2);
}

export function getPositionY(boxType, boxSide) {
    let value = boxes[boxType]['y'][boxSide];

    return value;
}

export function getPositionYCenter(boxType, boxSide) {
    let value = getPositionY(boxType, boxSide);
    let height = getHeight(boxType, boxSide);

    return +value + (+height/2);
}
export function getWidth(boxType, boxSide) {
    let value = boxes[boxType]['width'][boxSide];

    return value;
}

export function getHeight(boxType, boxSide) {
    let value = boxes[boxType]['height'][boxSide];

    return value;
}

export function getLabel(boxSide) {
    let value = boxLabels[boxSide];
    return value;
}

export function getBoxTypes() {
    return [
        { key: '125B' },
        { key: '1590A' },
        { key: '1590B' },
        { key: '1590BB' },
        { key: '1590BB2' },
        { key: '1590DD' },
        { key: '1590XX' }
    ];
    // { key: '1590DD' },
}

export function getBoxSides() {
    return [
        { key: 'A', label: 'Side A / Face' },
        { key: 'B', label: 'Side B / Top' },
        { key: 'C', label: 'Side C / Left' },
        { key: 'D', label: 'Side D / Bottom' },
        { key: 'E', label: 'Side E / Right' },
        { key: 'Lid', label: 'Enclosure Lid' },
    ];
}
export function getDefaultBoxType() {
    return defaultBoxType;
}
export function getDefaultBoxSide() {
    return defaultBoxSide;
}
export function getDefaultShapeType() {
    return defaultShapeType;
}
export function getDefaultDrillHoleQuantity() {
    return defaultDrillHoleQuantity;
}
export function getValidEnclosureTypes() {
    return validEnclosureTypes;
}
export function pluralize(word, num) {
    let suffix = "s"
    if (num === 1) {
        suffix = ""
    }

    return word + suffix
}
export function getApiUrl() {
    return apiUrl;
}
export function humanize(word) {
    return word.replace(/[_\s]+/g, ' ').replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
}