import React, { Component } from "react";

import Footer from "../Layout/Footer";
import Pagination from "../Layout/Pagination";

import OAuthService from '../Auth/OAuthService';

import {
    Row, Col,
    Container,
    Button,
    // Table
    Alert
} from 'reactstrap';
import BoxOrder from "./BoxOrder";
import BoxJob from "./BoxJob";

import { humanize } from '../../box-helpers'

class MyUVPrintTemplates extends Component {
    constructor(props){
        super(props);
        this.os = new OAuthService();
    }

    componentDidMount = () => {

    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="mb-2 text-center">
                                <h3>UV Print Templates</h3>
                            </div>
                            <div>
                                <Alert color="success" className="text-left">
                                    <div className="text-success">
                                        <h5>Latest Features!</h5>
                                        <b>Print on all Box Sides</b> - Create UV print templates for each side of the box enclosure.
                                        <b>Enclosure Lids</b> - You can UV print on the lids of enclosure if you can order the service for enclosure lids.
                                    </div>
                                </Alert>
                                <a className="btn btn-sm btn-outline-primary float-right" href="/box-uv-designs/new">+ New UV Template</a>
                                <div style={{ clear: 'both'}}></div>
                                <Pagination
                                    page_size_key={"_boxUVDesignsPageSize"}
                                    url={process.env.REACT_APP_API_URL + '/api/v4/box_uv_designs'}
                                    data_attr={"box_uv_designs"}
                                    redirect_to={"box-uv-designs"}
                                    show_archived={false}
                                    show_incomplete={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default MyUVPrintTemplates;
