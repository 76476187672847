import React from 'react';
import { useState } from "react";
import { createBrowserHistory } from 'history';

import { Row, Col, Container, Form, FormGroup, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane, Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Stage, Layer, Rect, Text, Circle, Line, Shape, Transformer } from 'react-konva';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
//import 'react-accessible-accordion/dist/fancy-example.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faCircle, faGripLinesVertical, faPlusCircle, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faTriangle, faRectangleVertical } from '@fortawesome/pro-solid-svg-icons';

import queryString from 'query-string';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { height } from '@fortawesome/pro-solid-svg-icons/fa0';

const convertMMToPX = (mm) => {
    return mm*3.78 
}
const convertPXToMM = (px) => {
    return px/3.78 
}

const checkCollision = (layerRef, shapeProps) => {
    var layer = layerRef.current;
    if(layer == null) return false;
    
    var collision = false;
    var base = null, target = null;
    layer.children.forEach(function (group) {
        if(group.attrs.id == "base"){
            base = group;
        }else if(group.attrs.id == shapeProps.graphic_id){
            target = group;
        }
    });

    if(base != null && target != null){
        if (haveIntersection(base.getClientRect(), target.getClientRect())) {
            collision = true
        } else {
            collision = false
        }
    }
    return collision;
};

const haveIntersection = (r1, r2) => {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    )
};

const DEFAULT_BASE_WIDTH = 400;
const DEFAULT_BASE_HEIGHT = 500;

const CustomShape = ({ shapeProps, isSelected, onSelect, onChange, layerRef }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const [collision, setCollision] = useState(false);
  
    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    React.useEffect(() => {
        var collision = checkCollision(layerRef, shapeProps);
        setCollision(collision);
    }, [shapeProps]);

    return (
        <React.Fragment>
            <Shape
                id={shapeProps.graphic_id}
                x={convertMMToPX(shapeProps.x)} y={convertMMToPX(shapeProps.y)}
                width={convertMMToPX(shapeProps.width)} height={convertMMToPX(shapeProps.height)} 
                offsetX={convertMMToPX(shapeProps.width)/2} offsetY={convertMMToPX(shapeProps.height)/2}
                fill={collision == true ? "#6464de" : "red"} strokeWidth={0}
                draggable={isSelected ? true : false}
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        new_x: e.target.x(),
                        new_y: e.target.y(),
                        out_of_bound: !collision
                    });
                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        new_x: node.x(),
                        new_y: node.y(),
                        new_width: Math.max(5, node.width() * scaleX),
                        new_height: Math.max(5, node.height() * scaleY),
                        out_of_bound: !collision
                    });
                }}
                sceneFunc = {(context, shape) => {
                    if(shapeProps.shape_type == "Triangle"){
                        context.beginPath();
                        context.lineTo(convertMMToPX(shapeProps.width)/2, convertMMToPX(shapeProps.height));
                        context.lineTo(convertMMToPX(shapeProps.width), 0);
                        context.lineTo(0, 0);
                        context.closePath();
                        context.fillStrokeShape(shape);
                    }else if(shapeProps.shape_type == "Rectangle"){
                        context.beginPath();
                        context.lineTo(0, convertMMToPX(shapeProps.height));
                        context.lineTo(convertMMToPX(shapeProps.width), convertMMToPX(shapeProps.height));
                        context.lineTo(convertMMToPX(shapeProps.width), 0);
                        context.lineTo(0, 0);
                        context.closePath();
                        context.fillStrokeShape(shape);
                    }
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    enabledAnchors={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
                    rotateEnabled={false}
                    flipEnabled={false}
                    keepRatio={false}
                    /*
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                            return newBox;
                    }}
                    */
                />
            )}
        </React.Fragment>
    );
};

const CustomCircle = ({ shapeProps, isSelected, onSelect, onChange, layerRef }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const [collision, setCollision] = useState(false);
  
    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    React.useEffect(() => {
        var collision = checkCollision(layerRef, shapeProps);
        setCollision(collision);
    }, [shapeProps]);

    return (
        <React.Fragment>
            <Circle
                id={shapeProps.graphic_id}
                x={convertMMToPX(shapeProps.x)} y={convertMMToPX(shapeProps.y)} radius={convertMMToPX(shapeProps.diameter/2)}
                fill={collision == true ? "#6464de" : "red"} strokeWidth={0}
                draggable={isSelected ? true : false}
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        new_x: e.target.x(),
                        new_y: e.target.y(),
                        out_of_bound: !collision
                    });
                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        new_x: node.x(),
                        new_y: node.y(),
                        new_diameter: Math.max(5, node.width() * scaleX),
                        out_of_bound: !collision
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    enabledAnchors={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
                    rotateEnabled={false}
                    flipEnabled={false}
                    /*
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                            return newBox;
                    }}
                    */
                />
            )}
        </React.Fragment>
    );
};

const CustomLine = ({ shapeProps, isSelected, onSelect, onChange, layerRef }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const [collision, setCollision] = useState(false);
  
    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    React.useEffect(() => {
        var collision = checkCollision(layerRef, shapeProps);
        setCollision(collision);
    }, [shapeProps]);

    return (
        <React.Fragment>
            {/*
            <Line 
                id={shapeProps.graphic_id}
                points={[convertMMToPX(shapeProps.x), convertMMToPX(shapeProps.y), convertMMToPX(shapeProps.x2), convertMMToPX(shapeProps.y2)]}
                strokeWidth={15}
                stroke={collision == true ? "#6464de" : "red"}
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
            />
            */}
            <Rect 
                id={shapeProps.graphic_id}
                x={convertMMToPX(shapeProps.x)} y={convertMMToPX(shapeProps.y)} 
                width={convertMMToPX(shapeProps.width)} 
                height={2.5} offsetY={1.25}
                rotation={shapeProps.angle}
                fill={collision == true ? "#6464de" : "red"} strokeWidth={0}
                draggable={isSelected ? true : false}
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                onDragEnd={(e) => {
                    var rads = shapeProps.angle * (Math.PI/180);
                    var opposite = Math.sin(rads) * convertMMToPX(shapeProps.width);
                    var adjacent = Math.cos(rads) * convertMMToPX(shapeProps.width);

                    var new_x2 = e.target.x() + adjacent;
                    var new_y2 = e.target.y() + opposite;
                    
                    onChange({
                        ...shapeProps,
                        new_x: e.target.x(),
                        new_y: e.target.y(),
                        new_x2, new_y2,
                        out_of_bound: !collision
                    });
                }}
                onTransformEnd={(e) => {
                    //console.log(e);
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    //console.log(scaleX);

                    node.scaleX(1);
                    node.scaleY(1);

                    var new_angle = node.rotation();
                    var new_width = Math.max(5, node.width() * scaleX);

                    var rads = Math.round(new_angle) * (Math.PI/180);
                    var opposite = Math.sin(rads) * Math.round(new_width);
                    var adjacent = Math.cos(rads) * Math.round(new_width);

                    var new_x2 = node.x() + adjacent;
                    var new_y2 = node.y() + opposite;
                
                    onChange({
                        ...shapeProps,
                        new_x: node.x(),
                        new_y: node.y(),
                        new_angle, new_width,
                        new_x2, new_y2,
                        out_of_bound: !collision
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={true}
                    enabledAnchors={['middle-left', 'middle-right']}
                    //rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
                    flipEnabled={false}
                    /*
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                            return newBox;
                    }}
                    */
                    padding={2}
                />
            )}
        </React.Fragment>
    );
};

class FaceplateDesignWrapper extends React.Component {
    editorRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            btnFilePopover: false,
            rightBar: true,
            currExpand: ["main-info"],
            loading: false,
            selectedId: "",
            flatDesign: null,
            name: "",
            thickness: 10,
            material_type: "acrylic",
            lines: [],
            circles: [],
            shapes: [],
            base: {
                width: DEFAULT_BASE_WIDTH,
                height: DEFAULT_BASE_HEIGHT,
                x: -(convertMMToPX(DEFAULT_BASE_WIDTH))/2,
                y: -(convertMMToPX(DEFAULT_BASE_HEIGHT))/2
            },
            stageScale: 1,
            stageX: 0,
            stageY: 0,
            stageWidth: 0,
            activeTab: "1",
            showLineButton: false,
            showHoleButton: true,
        }

        this.stageContainerRef = React.createRef();
        this.stageRef = React.createRef();
        this.layerRef = React.createRef();
    }

    componentDidMount() {
        const path = window.location.pathname.split("/")
        const parsed = queryString.parse(window.location.search);

        switch(path[path.length-1]){
            case "new":
                if(parsed["id"]){
                    if(parsed["id"]){
                        this.setState({
                            loading: true
                        }, () => {
                            this.getFlatDesign(parsed.id, true)
                        })
                    }

                }else if(parsed["public_key"]){
                    this.setState({
                        loading: true
                    }, () => {
                        this.getFlatDesign_wPublicKey(parsed.public_key)
                    })
                }else{
                    this.handleAddCircle();
                }
                break;
            case "edit":
                if(parsed["id"]){
                    this.setState({
                        loading: true
                    }, () => {
                        this.getFlatDesign(parsed.id, false)
                    })
                }
                break;
            default:
                break;
        }

        this.setState({
            stageWidth: document.getElementById("box-design-wrapper").offsetWidth
        }, () => {
            if(this.stageRef.current){
                var wRatio = this.stageRef.current.attrs.width / convertMMToPX(DEFAULT_BASE_WIDTH);
                var hRatio = this.stageRef.current.attrs.height / convertMMToPX(DEFAULT_BASE_HEIGHT);
                var ratio = Math.min(hRatio, wRatio);
                
                var new_stage_x = (convertMMToPX(DEFAULT_BASE_WIDTH*ratio)/2) + ((this.stageRef.current.attrs.width - convertMMToPX(DEFAULT_BASE_WIDTH*ratio))/2);
                var new_stage_y = convertMMToPX(DEFAULT_BASE_HEIGHT*ratio)/2 + 10;
    
                this.setState({
                    stageScale: ratio,
                    stageX: new_stage_x,
                    stageY: new_stage_y,
                })
            }
        });
    }

    getFlatDesign = async (id, duplicate) => {
        let token = JSON.parse(localStorage.getItem("token"));
        var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/flat_designs/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
        if(result["flat_design"]){
            this.setState({
                rightBar: true,
                flatDesign: duplicate == false ? result["flat_design"] : null,
            }, () => {
                if(duplicate) result["flat_design"].name = "";
                this.loadFlatDesign(result["flat_design"]);
            })
        }else{
            this.setState({
                loading: false
            }, () => {
                if(result["message"]) alert(result.message);
                const history = createBrowserHistory();
                history.push({ pathname: "/faceplate-designs/new" })
                window.location.reload();
            })
        }
    }

    getFlatDesign_wPublicKey = async (public_key) => {
        let token = JSON.parse(localStorage.getItem("token"));
        var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/flat_designs/new?public_key=' + public_key, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });
        if(result["flat_design"]){
            result["flat_design"].name = "";
            this.loadFlatDesign(result["flat_design"]);
        }else{
            this.setState({
                rightBar: true,
                loading: false
            }, () => {
                if(result["message"]) alert(result.message);
                const history = createBrowserHistory();
                history.push({ pathname: "/faceplate-designs/new" })
                window.location.reload();
            })
        }
    }

    loadFlatDesign = (flat_design) => {
        console.log(flat_design);
        var new_circles = [], new_lines = [], new_shapes = [];
        flat_design.flat_design_holes.forEach((a, index) => {
            var circle = {
                graphic_id: "circle-" + (index + 1),
                id: a.id,
                x: a.position_x,
                y: a.position_y,
                diameter: a.diameter
            }
            new_circles.push(circle)
        })

        flat_design.flat_design_lines.forEach((a, index) => {
            var deltaX = a.position_x1 - a.position_x2;
            var deltaY = a.position_y1 - a.position_y2;
            var angle = Math.atan2(deltaY, deltaX) * (180/Math.PI) + 180;
            
            var x3 = a.position_x2, y3 = a.position_y1;
            var base = x3 - a.position_x1;
            var height = a.position_y2 - y3; 
            var hypo = Math.sqrt(Math.pow(base, 2) + Math.pow(height, 2));
            
            var line = {
                graphic_id: "line-" + (index + 1),
                id: a.id,
                x: a.position_x1,
                y: a.position_y1,
                x2: a.position_x2,
                y2: a.position_y2,
                angle, 
                width: hypo,
            }
            new_lines.push(line);
        })

        flat_design.flat_design_shapes.forEach((a, index) => {
            var shape = {
                graphic_id: "shape-" + (index + 1),
                id: a.id,
                x: a.position_x,
                y: a.position_y,
                width: a.width,
                height: a.height,
                shape_type: a.shape_type
            }
            new_shapes.push(shape)
        })

        this.setState({
            loading: false,
            name: flat_design.name,
            thickness: flat_design.thickness,
            material_type: flat_design.material_type,
            circles: new_circles,
            lines: new_lines,
            shapes: new_shapes,
            base: {
                width: flat_design.width,
                height: flat_design.height,
                x: -(convertMMToPX(flat_design.width))/2,
                y: -(convertMMToPX(flat_design.height))/2
            },
        }, () => {
            if(this.stageRef.current){
                var wRatio = this.stageRef.current.attrs.width / convertMMToPX(flat_design.width);
                var hRatio = this.stageRef.current.attrs.height / convertMMToPX(flat_design.height);
                var ratio = Math.min(hRatio, wRatio);
    
                var new_stage_x = (convertMMToPX(flat_design.width*ratio)/2) + ((this.stageRef.current.attrs.width - convertMMToPX(flat_design.width*ratio))/2);
                var new_stage_y = convertMMToPX(flat_design.height*ratio)/2 + 10;
    
                this.setState({
                    stageScale: ratio,
                    stageX: new_stage_x,
                    stageY: new_stage_y,
                })
            }
        })
    }

    handleAddCircle = (event) => {
        if(event) event.preventDefault();

        var new_circles = JSON.parse(JSON.stringify(this.state.circles));
        var new_circle = {
            graphic_id: "circle-" + (new_circles.length + 1),
            x: 0,
            y: 0,
            diameter: 50,
        }

        new_circles.push(new_circle);
        //console.log(new_circles);
        this.setState({
            rightBar: false,
            currExpand: [new_circle.graphic_id],
            activeTab: "1",
            selectedId: new_circle.graphic_id,
            circles: new_circles
        }, () => {
            this.setState({ rightBar: true })
        })
    };

    handleDeleteCircle = (index) => {
        var new_circles = JSON.parse(JSON.stringify(this.state.circles));
        new_circles.splice(index, 1);

        this.setState({
            selectedId: "",
            circles: new_circles
        })
    }

    handleAddLine = (event) => {
        if(event) event.preventDefault();

        var new_lines = JSON.parse(JSON.stringify(this.state.lines));
        var new_line = {
            graphic_id: "line-" + (new_lines.length + 1),
            x: -100,
            y: 0,
            x2: 100,
            y2: 0,
            angle: 0,
            width: 200,
        }

        new_lines.push(new_line);
        //console.log(new_lines);
        this.setState({
            rightBar: false,
            currExpand: [new_line.graphic_id],
            activeTab: "2",
            selectedId: new_line.graphic_id,
            lines: new_lines
        }, () => {
            this.setState({ rightBar: true })
        })
    };

    handleDeleteLine = (index) => {
        var new_lines = JSON.parse(JSON.stringify(this.state.lines));
        new_lines.splice(index, 1);

        this.setState({
            selectedId: "",
            lines: new_lines
        })
    }

    handleAddShape = (event, type) => {
        if(event) event.preventDefault();

        var new_shapes = JSON.parse(JSON.stringify(this.state.shapes));
        var new_shape = {
            graphic_id: "shape-" + (new_shapes.length + 1),
            x: 0,
            y: 0,
            shape_type: type,
            width: 50,
            height: 50
        }

        new_shapes.push(new_shape);
        this.setState({
            rightBar: false,
            currExpand: [new_shape.graphic_id],
            activeTab: "3",
            selectedId: new_shape.graphic_id,
            shapes: new_shapes
        }, () => {
            this.setState({ rightBar: true })
        })
        return;
    }

    handleDeleteShape = (index) => {
        var new_shapes = JSON.parse(JSON.stringify(this.state.shapes));
        new_shapes.splice(index, 1);

        this.setState({
            selectedId: "",
            shapes: new_shapes
        })
    }

    deselectAll = (e) => {
        var clickedOnEmpty = e.target === e.target.getStage() || e.target.attrs.id === "base";
        if (clickedOnEmpty) {
            this.setState({
                selectedId: "",
            })
        }
    }

    handleOnCirlceChange = (new_attrs) => {
        var new_circles = JSON.parse(JSON.stringify(this.state.circles));
        var index = new_circles.findIndex(a => a.graphic_id == new_attrs.graphic_id);
        if(index != -1){
            new_circles[index].x = Math.round(convertPXToMM(new_attrs.new_x));
            new_circles[index].y = Math.round(convertPXToMM(new_attrs.new_y));
            if(new_attrs.new_diameter){
                new_circles[index].diameter = Math.round(convertPXToMM(new_attrs.new_diameter));
            }
        }
        this.setState({
            circles: new_circles
        })
    }

    handleOnLineChange = (new_attrs) => {
        var new_lines = JSON.parse(JSON.stringify(this.state.lines));
        var index = new_lines.findIndex(a => a.graphic_id == new_attrs.graphic_id);
        if(index != -1){
            new_lines[index].x = Math.round(convertPXToMM(new_attrs.new_x));
            new_lines[index].y = Math.round(convertPXToMM(new_attrs.new_y));

            if(new_attrs.new_width){
                new_lines[index].width = Math.round(convertPXToMM(new_attrs.new_width));
            }
            if(new_attrs.new_angle){
                new_lines[index].angle = Math.round(new_attrs.new_angle);
            }
            if(new_attrs.new_x2){
                new_lines[index].x2 = Math.round(convertPXToMM(new_attrs.new_x2));
            }
            if(new_attrs.new_y2){
                new_lines[index].y2 = Math.round(convertPXToMM(new_attrs.new_y2));
            }

        }
        this.setState({
            lines: new_lines
        })   
    }

    handleOnShapeChange = (new_attrs) => {
        var new_shapes = JSON.parse(JSON.stringify(this.state.shapes));
        var index = new_shapes.findIndex(a => a.graphic_id == new_attrs.graphic_id);
        if(index != -1){
            new_shapes[index].x = Math.round(convertPXToMM(new_attrs.new_x));
            new_shapes[index].y = Math.round(convertPXToMM(new_attrs.new_y));
            if(new_attrs.new_width){
                new_shapes[index].width = Math.round(convertPXToMM(new_attrs.new_width));
            }
            if(new_attrs.new_height){
                new_shapes[index].height = Math.round(convertPXToMM(new_attrs.new_height));
            }
        }
        this.setState({
            shapes: new_shapes
        })
    }

    handleWheel = e => {
        e.evt.preventDefault();
    
        const scaleBy = 1.02;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = {
          x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
          y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        };

        const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        //console.log(newScale);
        this.setState({
            stageScale: newScale,
            stageX: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
            stageY: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
        });
        //find("Transformer").forceUpdate();
        //console.log(this.stageRef.current);
        //this.stageRef.current.find("Transformer").forceUpdate();
    }

    boxInputChange = (type, event) => {
        event.preventDefault();

        var new_value = event.target.value;
        var new_base = JSON.parse(JSON.stringify(this.state.base));

        
        switch(type){
            case "width":
                new_base.width = new_value;
                new_base.x = -(convertMMToPX(new_value))/2;
                break;
            case "height":
                new_base.height = new_value;
                new_base.y = -(convertMMToPX(new_value))/2;
                break;
        }

        this.setState({
            base: new_base
        })
    }

    circleInputChange = (type, index, event) => {
        event.preventDefault();

        var new_value = event.target.value;
        var new_circles = JSON.parse(JSON.stringify(this.state.circles));

        switch(type){
            case "diameter":
                new_circles[index].diameter = Number(new_value);
                break;
            case "x":
                new_circles[index].x = Number(new_value);
                break;
            case "y":
                new_circles[index].y = Number(new_value);
                break;
        }

        this.setState({
            selectedId: new_circles[index].graphic_id,
            circles: new_circles
        })
    }

    lineInputChange = (type, index, event) => {
        event.preventDefault();

        var new_value = event.target.value;
        var new_lines = JSON.parse(JSON.stringify(this.state.lines));

        switch(type){
            case "x":
                new_lines[index].x = Number(new_value);
                break;
            case "y":
                new_lines[index].y = Number(new_value);
                break
            case "x2":
                new_lines[index].x2 = Number(new_value);
                break
            case "y2":
                new_lines[index].y2 = Number(new_value);
                break
        }

        var deltaX = new_lines[index].x - new_lines[index].x2;
        var deltaY = new_lines[index].y - new_lines[index].y2;
        var angle = Math.atan2(deltaY, deltaX) * (180/Math.PI) + 180;
        
        var x3 = new_lines[index].x2, y3 = new_lines[index].y;
        var base = x3 - new_lines[index].x;
        var height = new_lines[index].y2 - y3; 
        var hypo = Math.sqrt(Math.pow(base, 2) + Math.pow(height, 2));

        new_lines[index].width = hypo;
        new_lines[index].angle = angle;

        this.setState({
            selectedId: new_lines[index].graphic_id,
            lines: new_lines
        })
    }

    shapeInputChange = (type, index, event) => {
        event.preventDefault();

        var new_value = event.target.value;
        var new_shapes = JSON.parse(JSON.stringify(this.state.shapes));

        switch(type){
            case "width":
                new_shapes[index].width = Number(new_value);
                break;
            case "height":
                new_shapes[index].height = Number(new_value);
                break;
            case "x":
                new_shapes[index].x = Number(new_value);
                break;
            case "y":
                new_shapes[index].y = Number(new_value);
                break;
            case "shape_type":
                new_shapes[index].shape_type = new_value;
                break;
        }

        this.setState({
            selectedId: new_shapes[index].graphic_id,
            shapes: new_shapes
        })
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => {
                if(this.state.activeTab == "1"){
                    this.setState({
                        showHoleButton: true,
                        showLineButton: false,
                    })
                }else{
                    this.setState({
                        showHoleButton: false,
                        showLineButton: true,
                    })
                }
            });
        }
    }

    handleSubmit = (e) => { 
        e.preventDefault();

        let final_circles = [], final_lines = [], final_shapes = [];
        this.state.circles.forEach(a => {
            final_circles.push({diameter: a.diameter, positionX: a.x, positionY: a.y})
        })

        this.state.lines.forEach(a => {
            final_lines.push({positionX1: a.x, positionY1: a.y, positionX2: a.x2, positionY2: a.y2})
        })

        this.state.shapes.forEach(a => {
            final_shapes.push({width: a.width, height: a.height, positionX: a.x, positionY: a.y, shape_type: a.shape_type})
        })

        let bodyParams = JSON.stringify({
            name: this.state.name,
            width: this.state.base.width,
            height: this.state.base.height,
            thickness: this.state.thickness,
            material_type: this.state.material_type,
            holes: final_circles,
            lines: final_lines,
            shapes: final_shapes
        });

        this.setState({
            selectedId: "",
            loading: true
        }, async () => {
            let token = JSON.parse(localStorage.getItem("token"));
            var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/flat_designs' + (this.state.flatDesign !== null ? '/' + this.state.flatDesign.id : ''), {
                method: this.state.flatDesign !== null ? 'PUT' : 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                body: bodyParams
            })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch((error) => {
                return error;
            });
            console.log(result);

            this.setState({
                loading: false
            }, () => {
                if(result["flat_design"]){
                    toast.success('Faceplate design successfully saved.');
                    if(this.state.flatDesign === null){
                        const history = createBrowserHistory();
                        history.push({ pathname: "/faceplate-designs/edit?id=" + result["flat_design"].id })
                    }
                    this.setState({
                        flatDesign: result["flat_design"]
                    })
                } else {
                    if(result["message"]) alert(result.message);
                }
            })
        });
    }

    checkCollision = (object) => {
        var layer = this.layerRef.current;
        
        var collision = true;
        var base = null, target = null;
        layer.children.forEach(function (group) {
            if(group.attrs.id == "base"){
                base = group;
            }else if(group.attrs.id == object.graphic_id){
                target = group;
            }
        });

        if(base != null && target != null){
            if (haveIntersection(base.getClientRect(), target.getClientRect())) {
                collision = true
            } else {
                collision = false
            }
        }
        return collision;
    }

    accordionChange = (accordiian_item_ids) => {
        //console.log(accordiian_item_ids);
        this.setState({
            currExpand: accordiian_item_ids
        });
    }
    
    render() {
        return (
            <div>
                <Header />
                <Container fluid className="px-0">
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable={false}
                        pauseOnHover={false}
                    />
                    { this.state.loading && (
                        <div className="loading style-2">
                            <div className="loading-wheel"></div>
                            <h3 className="loading-message">THIS MIGHT TAKE A FEW MINUTES. PLEASE WAIT...</h3>
                        </div>
                    )}
                    <div className='w-100 d-flex flex-row' style={{borderBottom: "1px solid silver"}}>
                        <div style={{height: window.innerHeight, backgroundColor: "rgb(246, 249, 252)", borderRight: "1px solid silver"}}>
                            <div className='px-3'>
                                <div className='text-center py-3'>
                                    <Button id="Popover1" color="light" className="p-0" /*onClick={e => {this.setState({ btnFilePopover: !this.state.btnFilePopover })}} */>
                                        <FontAwesomeIcon icon={faFile} size={"2x"}/>
                                    </Button>
                                    <Popover
                                        toggle={ e => this.setState({ btnFilePopover: !this.state.btnFilePopover }) }
                                        target="Popover1"
                                        isOpen={this.state.btnFilePopover}
                                        placement={"right-start"}
                                        trigger="click"
                                    >
                                        <PopoverHeader>
                                            Project
                                        </PopoverHeader>
                                        <PopoverBody>
                                            <Form>
                                                <FormGroup row>
                                                    <Col className="col" md={12}>
                                                        <Label className="col-form-label">Design Name</Label>
                                                        <Input value={this.state.name} type="text" onChange={e => this.setState({ name: e.target.value })}placeholder="Faceplate Design Name"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <hr />
                                                <FormGroup row className="mt-4">
                                                    <Col sm={2} className="pr-0 d-flex align-items-center">
                                                        Base:
                                                    </Col>
                                                    <Col sm={10} className="pr-0">
                                                        <Container>
                                                            <Row>
                                                                <Col sm={6} className="pr-0">
                                                                    Width(mm)
                                                                </Col>
                                                                <Col sm={6}>
                                                                    Height(mm)
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={6} className="pr-0">
                                                                    <Input
                                                                        value={this.state.base.width}
                                                                        type="number"
                                                                        min="1"
                                                                        onChange={(e) => this.boxInputChange("width", e)}
                                                                    />
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Input
                                                                        value={this.state.base.height}
                                                                        type="number"
                                                                        min="1"
                                                                        onChange={(e) => this.boxInputChange("height", e)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                </FormGroup>
                                                <hr />
                                                <FormGroup row>
                                                    <Col className="col" md={12}>
                                                        <Label className="col-form-label">Thickness(mm)</Label>
                                                        <Input value={this.state.thickness} type="number" min="1" onChange={e => this.setState({ thickness: e.target.value })} 
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <hr />
                                                <FormGroup row>
                                                    <Col className="col" md={12}>
                                                        <Label className="col-form-label">Material Type</Label>
                                                        <Input value={this.state.material_type} type="text" onChange={e => this.setState({ material_type: e.target.value })} 
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </PopoverBody>
                                    </Popover>
                                </div>
                                <div className='text-center py-3'>
                                    <Button onClick={e => this.handleAddCircle(e)} color="light" className="p-0">
                                        <FontAwesomeIcon icon={faCircle} size={"2x"}/>
                                        <FontAwesomeIcon icon={faPlusCircle} style={{position: "absolute", margin: "20px 0 0 -35px", backgroundColor: "white", borderRadius: "25px", border: "1px solid white"}}/>
                                    </Button>
                                </div>
                                <div className='text-center py-3'>
                                    <Button onClick={e => this.handleAddLine(e)} color="light" className="p-0">
                                        <FontAwesomeIcon icon={faGripLinesVertical} size={"2x"}/>
                                        <FontAwesomeIcon icon={faPlusCircle} style={{position: "absolute", margin: "20px 0 0 -25px", backgroundColor: "white", borderRadius: "25px", border: "1px solid white"}}/>
                                    </Button>
                                </div>

                                <div className='text-center py-3'>
                                    <Button onClick={e => this.handleAddShape(e, "Rectangle")} color="light" className="p-0">
                                        <FontAwesomeIcon icon={faRectangleVertical} size={"2x"}/>
                                        <FontAwesomeIcon icon={faPlusCircle} style={{position: "absolute", margin: "20px 0 0 -30px", backgroundColor: "white", borderRadius: "25px", border: "1px solid white"}}/>
                                    </Button>
                                </div>

                                <div className='text-center py-3'>
                                    <Button onClick={e => this.handleAddShape(e, "Triangle")} color="light" className="p-0">
                                        <FontAwesomeIcon icon={faTriangle} size={"2x"}/>
                                        <FontAwesomeIcon icon={faPlusCircle} style={{position: "absolute", margin: "20px 0 0 -38px", backgroundColor: "white", borderRadius: "25px", border: "1px solid white"}}/>
                                    </Button>
                                </div>

                                <div className='text-center py-3'>
                                    <Button onClick={e => this.handleSubmit(e)} color="light" className="p-0">
                                        <FontAwesomeIcon icon={faSave} size={"2x"}/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div style={{float: "left", width: this.state.rightBar ? "calc(100% - 300px)" : "100%"}}>
                            <div id='box-design-wrapper'>
                                <Stage ref={this.stageRef}
                                    width={this.state.stageWidth} height={window.innerHeight} 
                                    onWheel={this.handleWheel}
                                    scaleX={this.state.stageScale}
                                    scaleY={-this.state.stageScale}
                                    x={this.state.stageX}
                                    y={this.state.stageY}
                                    onMouseDown={e => this.deselectAll(e)}
                                    onTouchStart={e => this.deselectAll(e)}
                                    draggable
                                    //onMouseMove={(e) => {
                                        //const pos = e.target.getStage().getPointerPosition();
                                        //console.log(pos);
                                    //}}
                                >
                                    <Layer ref={this.layerRef}>
                                        <Rect
                                            id={"base"}
                                            x={this.state.base.x}
                                            y={this.state.base.y}
                                            width={convertMMToPX(this.state.base.width)}
                                            height={convertMMToPX(this.state.base.height)}
                                            fill="#e3e3e3"
                                            stroke="black"
                                        />
                                        {this.state.circles.length > 0 && this.state.circles.map((circle, i) => (
                                            <CustomCircle
                                                key={i}
                                                layerRef={this.layerRef}
                                                shapeProps={circle}
                                                isSelected={circle.graphic_id === this.state.selectedId}
                                                onSelect={() => {
                                                    this.setState({
                                                        activeTab: "1",
                                                        selectedId: circle.graphic_id,
                                                        rightBar: false,
                                                        currExpand: [circle.graphic_id]
                                                    }, () => {
                                                        this.setState({ rightBar: true })
                                                    })
                                                }}
                                                onChange={(newAttrs) => {
                                                    this.handleOnCirlceChange(newAttrs);
                                                }}
                                            />
                                        ))}
                                        {/*this.state.lines.length > 0 &&
                                            <>
                                            <Circle 
                                                x={convertMMToPX(this.state.lines[0].x)} 
                                                y={convertMMToPX(this.state.lines[0].y)} 
                                                radius={20}
                                                fill={"green"} strokeWidth={0}
                                            />
                                            <Circle 
                                                x={convertMMToPX(this.state.lines[0].x2)} 
                                                y={convertMMToPX(this.state.lines[0].y2)} 
                                                radius={20}
                                                fill={"green"} strokeWidth={0}
                                            />
                                            </>
                                        */}
                                        {this.state.lines.length > 0 && this.state.lines.map((line, i) => (
                                            <CustomLine
                                                key={i}
                                                layerRef={this.layerRef}
                                                shapeProps={line}
                                                isSelected={line.graphic_id === this.state.selectedId}
                                                onSelect={() => {
                                                    this.setState({
                                                        activeTab: "2",
                                                        selectedId: line.graphic_id,
                                                        rightBar: false,
                                                        currExpand: [line.graphic_id]
                                                    }, () => {
                                                        this.setState({ rightBar: true })
                                                    })
                                                }}
                                                onChange={(newAttrs) => {
                                                    this.handleOnLineChange(newAttrs);
                                                }}
                                            />
                                        ))}
                                        {this.state.shapes.length > 0 && this.state.shapes.map((shape, i) => (
                                            <CustomShape
                                                key={i}
                                                layerRef={this.layerRef}
                                                shapeProps={shape}
                                                isSelected={shape.graphic_id === this.state.selectedId}
                                                onSelect={() => {
                                                    this.setState({
                                                        activeTab: "3",
                                                        selectedId: shape.graphic_id,
                                                        rightBar: false,
                                                        currExpand: [shape.graphic_id]
                                                    }, () => {
                                                        this.setState({ rightBar: true })
                                                    })
                                                }}
                                                onChange={(newAttrs) => {
                                                    this.handleOnShapeChange(newAttrs);
                                                }}
                                            />
                                        ))}
                                    </Layer>
                                </Stage>
                            </div>
                        </div>
                        {this.state.rightBar &&
                            <div style={{maxHeight: window.innerHeight, width: "300px", backgroundColor: "rgb(246, 249, 252)", overflowY: "scroll", borderLeft: "1px solid silver" }}>
                                <Accordion preExpanded={this.state.currExpand} allowMultipleExpanded allowZeroExpanded
                                    onChange={this.accordionChange}
                                >
                                    <AccordionItem uuid={"main-info"} id={"main-info"}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {this.state.name != "" ? this.state.name : "Faceplate Design"}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div>
                                                Base: {this.state.base.width} * {this.state.base.height} mm
                                            </div>
                                            <div>
                                                Thickness: {this.state.thickness} mm
                                            </div>
                                            <div>
                                                Material: {this.state.material_type}
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <Nav tabs className="design-custom-tabs" justified>
                                        <NavItem>
                                            <NavLink className={this.state.activeTab === '1' ? "active" : ""} onClick={() => { this.toggleTab('1'); }}>
                                                <div className='py-2'><label className="col-form-label py-0" style={{fontWeight: "bold", color: "black"}}>Holes</label></div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={this.state.activeTab === '2' ? "active" : ""} onClick={() => { this.toggleTab('2'); }} >
                                            <div className='py-2'><label className="col-form-label py-0" style={{fontWeight: "bold", color: "black"}}>Lines</label></div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={this.state.activeTab === '3' ? "active" : ""} onClick={() => { this.toggleTab('3'); }} >
                                            <div className='py-2'><label className="col-form-label py-0" style={{fontWeight: "bold", color: "black"}}>Shapes</label></div>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            {this.state.circles.length > 0 && this.state.circles.map((circle, index) => {
                                                return (<AccordionItem key={"circle-" + index} uuid={circle.graphic_id} id={circle.graphic_id}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton style={{color: this.checkCollision(circle) ? "black" : "red"}}>
                                                            Hole #{index+1}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <Container className="p-0">
                                                            <Row className="">
                                                                <Col className="col px-1 d-flex align-items-center" md={4}>
                                                                    <Label className="hole-label">Diameter (mm)</Label>
                                                                </Col>
                                                                <Col className="col px-1 d-flex align-items-center" md={4}>
                                                                    <Label className="hole-label">X Position (mm)</Label>
                                                                </Col>
                                                                <Col className="col px-1 d-flex align-items-center" md={4}>
                                                                    <Label className="hole-label">Y Position (mm)</Label>
                                                                </Col>
                                                            </Row>
                                                            <Row className="">
                                                                <Col className="col px-1" md={4}>
                                                                    <Input value={circle.diameter} onChange={(e) => this.circleInputChange("diameter", index, e)} type="number" />
                                                                </Col>
                                                                <Col className="col px-1" md={4}>
                                                                    <Input value={circle.x} onChange={(e) => this.circleInputChange("x", index, e)} type="number"/>
                                                                </Col>
                                                                <Col className="col px-1" md={4}>
                                                                    <Input value={circle.y} onChange={(e) => this.circleInputChange("y", index, e)} type="number"/>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col className="col px-1 text-center" md={12}>
                                                                    <Button color="light" onClick={(e) => this.handleDeleteCircle(index)} style={{border: "1px solid silver"}}>Remove{" "}<FontAwesomeIcon icon={faTrash} size={"1x"} color={"black"} /></Button>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </AccordionItemPanel>
                                                </AccordionItem>)
                                            })}
                                            {this.state.circles.length == 0 &&
                                                <div className='p-2 text-center'>
                                                    No hole added
                                                </div>
                                            }
                                        </TabPane>
                                        <TabPane tabId="2">
                                            {this.state.lines.length > 0 && this.state.lines.map((line, index) => {
                                                return(<AccordionItem key={"line-" + index} uuid={line.graphic_id} id={line.graphic_id}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton style={{color: this.checkCollision(line) ? "black" : "red"}}>
                                                            Line #{index+1}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <Container className="p-0">
                                                            <Row className="">
                                                                <Col className="col px-1 d-flex align-items-center">
                                                                    <Label className="line-label">X1 (mm) Position</Label>
                                                                </Col>
                                                                <Col className="col px-1 d-flex align-items-center">
                                                                    <Label className="line-label">Y1 (mm) Position</Label>
                                                                </Col>
                                                            </Row>
                                                            <Row className="">
                                                                <Col className="col px-1">
                                                                    <Input value={line.x} onChange={(e) => this.lineInputChange("x", index, e)} type="number" />
                                                                </Col>
                                                                <Col className="col px-1">
                                                                    <Input value={line.y} onChange={(e) => this.lineInputChange("y", index, e)} type="number" />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col className="col px-1 d-flex align-items-center">
                                                                    <Label className="line-label">X2 (mm) Position</Label>
                                                                </Col>
                                                                <Col className="col px-1 d-flex align-items-center">
                                                                    <Label className="line-label">Y2 (mm) Position</Label>
                                                                </Col>
                                                            </Row>
                                                            <Row className="">
                                                                <Col className="col px-1">
                                                                    <Input value={line.x2} onChange={(e) => this.lineInputChange("x2", index, e)} type="number" />
                                                                </Col>
                                                                <Col className="col px-1">
                                                                    <Input value={line.y2} onChange={(e) => this.lineInputChange("y2", index, e)} type="number" />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col className="col px-1 text-center" md={12}>
                                                                    <Button color="light" onClick={(e) => this.handleDeleteLine(index)} style={{border: "1px solid silver"}}>Remove{" "}<FontAwesomeIcon icon={faTrash} size={"1x"} color={"black"} /></Button>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </AccordionItemPanel>
                                                </AccordionItem>)
                                            })}
                                            {this.state.lines.length == 0 &&
                                                <div className='p-2 text-center'>
                                                    No line added
                                                </div>
                                            }
                                        </TabPane>
                                        <TabPane tabId="3">
                                            {this.state.shapes.length > 0 && this.state.shapes.map((shape, index) => {
                                                return (<AccordionItem key={"shape-" + index} uuid={shape.graphic_id} id={shape.graphic_id}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton style={{color: this.checkCollision(shape) ? "black" : "red"}}>
                                                            Shape #{index+1}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <Container className="p-0">
                                                            <Row className="">
                                                                <Col className="col px-1 d-flex align-items-center" md={3}>
                                                                    <Label className="hole-label">Type</Label>
                                                                </Col>
                                                                <Col className="col px-1 d-flex align-items-center" md={9}>
                                                                    <Input name="select" type="select" value={shape.shape_type} onChange={(e) => this.shapeInputChange("shape_type", index, e)}>
                                                                        <option>Rectangle</option>
                                                                        <option>Triangle</option>
                                                                    </Input>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col className="col px-1 d-flex align-items-center" md={6}>
                                                                    <Label className="hole-label">Width (mm)</Label>
                                                                </Col>
                                                                <Col className="col px-1 d-flex align-items-center" md={6}>
                                                                    <Label className="hole-label">Height (mm)</Label>
                                                                </Col>
                                                            </Row>
                                                            <Row className="">
                                                                <Col className="col px-1" md={6}>
                                                                    <Input value={shape.width} onChange={(e) => this.shapeInputChange("width", index, e)} type="number"/>
                                                                </Col>
                                                                <Col className="col px-1" md={6}>
                                                                    <Input value={shape.height} onChange={(e) => this.shapeInputChange("height", index, e)} type="number"/>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col className="col px-1 d-flex align-items-center" md={6}>
                                                                    <Label className="hole-label">X Position (mm)</Label>
                                                                </Col>
                                                                <Col className="col px-1 d-flex align-items-center" md={6}>
                                                                    <Label className="hole-label">Y Position (mm)</Label>
                                                                </Col>
                                                            </Row>
                                                            <Row className="">
                                                                <Col className="col px-1" md={6}>
                                                                    <Input value={shape.x} onChange={(e) => this.shapeInputChange("x", index, e)} type="number"/>
                                                                </Col>
                                                                <Col className="col px-1" md={6}>
                                                                    <Input value={shape.y} onChange={(e) => this.shapeInputChange("y", index, e)} type="number"/>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col className="col px-1 text-center" md={12}>
                                                                    <Button color="light" onClick={(e) => this.handleDeleteShape(index)} style={{border: "1px solid silver"}}>Remove{" "}<FontAwesomeIcon icon={faTrash} size={"1x"} color={"black"} /></Button>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </AccordionItemPanel>
                                                </AccordionItem>)
                                            })}
                                            {this.state.shapes.length == 0 &&
                                                <div className='p-2 text-center'>
                                                    No shape added
                                                </div>
                                            }
                                        </TabPane>
                                    </TabContent>
                                </Accordion>
                            </div>
                        }
                    </div>
                    <div style={{clear: "both"}} />
                    <div className="card bg-light mb-3 mx-4 mt-4">
                        <div className="card-body">
                            <p className="card-text text-danger">This form is only for illustration purposes and actual measurements for coordinates should be taken with actual enclosure itself. The mounting holes for the bottom plate are usually in the bottom corners of side plates.</p>
                            <p className="card-text">If you wish to move hole to left side please put minus value in X value,
                            for example if you put -4 it will move hole 4mm to left, if you put just "4" to X value then it will move hole to 4mm right side.
                            Same applies to Y value, if you put minus value for example -4, it will move hole 4mm to downwards and if you just put "4" to Y value it will move hole 4mm upwards.
                            If hole touch or exceed border it will turn to red color.</p>
                            <p className="card-text">**Minimum hole size is 3mm. And please add 0.20mm to hole diameter for powder coating thickness, for example if you need 4mm hole size please put 4.2 value to hole size.</p>
                        </div>
                    </div>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default FaceplateDesignWrapper;