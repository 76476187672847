import React, { Component } from "react";

import Footer from "../Layout/Footer";
import Pagination from "../Layout/Pagination";

import OAuthService from '../Auth/OAuthService';

import {
    Row, Col,
    Container,
    Button,
    // Table
    Alert
} from 'reactstrap';
import BoxOrder from "./BoxOrder";
import BoxJob from "./BoxJob";

import { humanize } from '../../box-helpers'

class MyDrillTemplates extends Component {
    constructor(props){
        super(props);
        this.os = new OAuthService();
    }

    componentDidMount = () => {

    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="mb-2 text-center">
                                <h3>Drill Templates</h3>
                            </div>
                            <div>
                                <Alert color="success" className="text-left">
                                    <div className="text-success">
                                        <h5>Latest Features!</h5>
                                        <b>Easy Shapes</b> - You can easily create Rectangles and Triangle shapes with the new Shapes feature in Drill Templates.
                                        <br />
                                        <b>Lines</b> - You can create rectangular drill patterns and any other shapes using Lines with the drill tool.
                                        For an example template for lines. <a href="/box-designs/new?public_key=UzlXTFdzVVBsRnh5WHU4OFdNTCs0UT09Cg==">Click Here</a>
                                        <br />
                                        <b>Enclosure Lids</b> - You can drill on the lids of enclosure if you can order the service for enclosure lids.
                                    </div>
                                </Alert>
                                <a className="btn btn-sm btn-outline-primary float-right" href="/box-designs/new">+ New Drill Template</a>
                                <div style={{ clear: 'both'}}></div>
                                <Pagination
                                    page_size_key={"_boxDesignsPageSize"}
                                    url={process.env.REACT_APP_API_URL + '/api/v4/box_designs'}
                                    data_attr={"box_designs"}
                                    redirect_to={"box-designs"}
                                    show_archived={false}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default MyDrillTemplates;
