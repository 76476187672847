export default class OAuthService {

    constructor(){

    }

    getToken = new Promise((resolve, reject) => {
        var body_params = new URLSearchParams();
        body_params.append("client_id", process.env.REACT_APP_CLIENT_ID);
        body_params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
        body_params.append("response_type", "token");
        body_params.append("grant_type", "client_credentials");

        fetch(process.env.REACT_APP_API_URL + '/oauth/token.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body_params.toString()
        })
        .then(response => {
            if(!response.ok){
                reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
            }else{
                resolve(response.json());
            }
        })
        .catch((error) => {
            reject(error);
        });
    });

    getUserToken = (username, password) => {
        return new Promise((resolve, reject) => {
            var body_params = new URLSearchParams();
            body_params.append("client_id", process.env.REACT_APP_CLIENT_ID);
            body_params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
            body_params.append("response_type", "token");
            body_params.append("grant_type", "password");
            body_params.append("username", username);
            body_params.append("password", password);
            body_params.append("owner_type", "box_customer");

            fetch(process.env.REACT_APP_API_URL + '/oauth/token.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: body_params.toString()
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    getUserInfo = (token) => {
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/api/v4/box_customers/me', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    getBoxJobs = (token, new_page, page_size) => {
        new_page = new_page || 1;
        page_size = page_size || 20;
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/api/v4/box_jobs/box_jobs?page=' + new_page + '&page_size=' + page_size, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    getBoxOrders = (token) => {
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/api/v4/box_jobs/box_orders', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    // resendConfirmation = (token) => {
    //     return new Promise((resolve, reject) => {
    //         fetch(process.env.REACT_APP_API_URL + '/api/v4/box_customers/resend_confirmation', {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: "Bearer " + token
    //             }
    //         })
    //         .then(response => {
    //             if(!response.ok){
    //                 reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
    //             }else{
    //                 resolve(response.json());
    //             }
    //         })
    //         .catch((error) => {
    //             reject(error);
    //         });
    //     });
    // }

}